
const $ = require('jquery');
const jQueryBridget = require('jquery-bridget');
const Masonry = require('masonry-layout');
const InfiniteScroll = require('infinite-scroll/');
jQueryBridget('infScroll', InfiniteScroll, $);
jQueryBridget('masonry', Masonry, $);
const imagesLoaded = require('imagesloaded');

function firstscript() {
  Masonry.imagesLoaded = imagesLoaded;

  let msnry = new Masonry('#container', {
    itemSelector: '.item',
    percentPosition: true,
    visibleStyle: { opacity: 0 },
    hiddenStyle: { opacity: 0 },
  });

  InfiniteScroll.imagesLoaded = imagesLoaded;

  const infScroll = new InfiniteScroll('#container', {
    path: '.pagination_next a',
    append: '.item',
    button: '.pagination_next a',
    scrollThreshold: false,
    status: '.page-load-status',
    checkLastPage: '.pagination_next a',
    outlayer: msnry,
    history: false,
  });
  infScroll.on('request', function () {
    $('.pagination_next a').css({ 'opacity': 0 });
    $('.inview').off('inview');
  });
  infScroll.on('append', function () {
    $('.inview').on('inview', function () {
      $(this).addClass('fadeInUp');
    });
    $('.inviewL').on('inview', function () {
      $(this).addClass('fadeInLeft');
    });
    $('.inviewR').on('inview', function () {
      $(this).addClass('fadeInRight');
    });
    $('.inviewZ').on('inview', function () {
      $(this).addClass('fadeInUpZoom');
    });
    $('.inviewF').on('inview', function () {
      $(this).addClass('fadeIn');
    });
    $('.pagination_next a').css({ 'opacity': 1 });
  });

  const infScrollNews = new InfiniteScroll('#news-container', {
    path: '.pagination_next a',
    append: '.item',
    button: '.pagination_next a',
    scrollThreshold: false,
    status: '.page-load-status',
    checkLastPage: '.pagination_next a',
    history: false,
  });
  infScrollNews.on('request', function () {
    $('.pagination_next a').css({ 'opacity': 0 });
    $('.inview').off('inview');

  });
  infScrollNews.on('append', function (event, body, path, items, response) {
    $('.inview').on('inview', function () {
      $(this).addClass('fadeInUp');
    });
    $('.inviewL').on('inview', function () {
      $(this).addClass('fadeInLeft');
    });
    $('.inviewR').on('inview', function () {
      $(this).addClass('fadeInRight');
    });
    $('.inviewZ').on('inview', function () {
      $(this).addClass('fadeInUpZoom');
    });
    $('.inviewF').on('inview', function () {
      $(this).addClass('fadeIn');
    });
    $('.pagination_next a').css({ 'opacity': 1 });
  });

}
window.onload = firstscript;





/*
$('#container').infinitescroll({	//無限読み込みをさせたい要素を囲う親のクラス名を指定
  navSelector: ".pagination_next",//次のページを読み込むリンクを囲んでいるクラス名を指定
  nextSelector: ".pagination_next a",//次のページにリンクする要素を指定
  itemSelector: "#container .item",//無限スクロールで表示をさせたい要素を指定
  //maxPage : 3,//読み込む全体のページ数。入れないと連番でURLが読まれて404エラーが出る
  animate: true, //アニメーション処理を行う
  loading: {
    finishedMsg: "全ての記事が読み込まれました", //全ての要素が読み込まれた後の終了メッセージ
    msgText: "読み込み中", //ローディング中の表示テキスト
    img: 'svg/loading.svg', //ローディング中の画像を指定
  },
},
  function (newArticle) {
    $(newArticle).hide().delay(100).fadeIn(900); // 0.9秒かけてフェードイン
    $(".pagination_next").appendTo(".article_list").css("display", "block"); //navigationをwrapperの一番最後に移動して表示
  });

//ボタンをクリックしたら表示する設定
$('#container').infinitescroll('unbind');//初期値にもどして
// ボタンをクリックしたら要素を表示。ボタンのリンク機能は無効
$(".pagination_next a").click(function () {
  $('#container').infinitescroll('retrieve');
  return false;
});

*/
