import 'jquery-inview';
$('.inview').on('inview', function () {
  $(this).addClass('fadeInUp');
});
$('.inviewL').on('inview', function () {
  $(this).addClass('fadeInLeft');
});
$('.inviewR').on('inview', function () {
  $(this).addClass('fadeInRight');
});
$('.inviewZ').on('inview', function () {
  $(this).addClass('fadeInUpZoom');
});
$('.inviewF').on('inview', function () {
  $(this).addClass('fadeIn');
});
