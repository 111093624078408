$('.tagsearch').on('click', function () {
  $(this).next().stop().slideToggle();
  $(this).stop().toggleClass('is_active');
});
$('.tags-list .current').prependTo('.tags-list');
$('.tags-list .more').appendTo('.tags-list');
$('.tags-list .more').on('click', function () {
  $('.tags-list a:nth-child(n+26)').css({ 'display': 'inline-block' });
  $(this).fadeOut(0);
  return false;
});



