import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

$('.top-mv-slides')
  .on('init', function (event, slick) {
    $('.now-count').text(slick.currentSlide + 1); // 現在のスライド番号(+1が無いと0からスタートしてしまう)
    $('.all-count').text(slick.slideCount); // スライドの総数
    $('.progress-bar').addClass('is_start');
  })
  .slick({
    arrows: true,
    dots: false,
    fade: true,
    infinite: true,
    easing: 'linear',
    autoplay: true,
    autoplaySpeed: 3500,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
    pauseOnFocus: false,
  })
  .on("beforeChange", function (event, slick, currentSlide, nextSlide) {
    $(".now-count").text(nextSlide + 1); // 現在のスライド番号の次のスライドになったら番号を+1
    $('.progress-bar').removeClass('is_start');
  })
  .on("afterChange", function (event, slick, currentSlide, nextSlide) {
    $('.progress-bar').addClass('is_start');
  })

$('.top-artists-slides')
  .slick({
    arrows: false,
    dots: false,
    infinite: true,
    cssEase: "linear",
    autoplay: true,
    slidesToShow: 1,
    autoplaySpeed: 0,
    speed: 15000,
    variableWidth: true,
    pauseOnHover: false,
    pauseOnFocus: false
  })
$('.top-artists-slides-rtl')
  .slick({
    arrows: false,
    dots: false,
    infinite: true,
    cssEase: "linear",
    autoplay: true,
    slidesToShow: 1,
    autoplaySpeed: 0,
    speed: 15000,
    variableWidth: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    rtl: true
  })

$('.about-slides').slick({
  arrows: false,
  dots: false,
  infinite: true,
  cssEase: "linear",
  autoplay: true,
  slidesToShow: 1,
  autoplaySpeed: 0,
  speed: 15000,
  variableWidth: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  variableWidth: true
});

