$(function () {
  let $tab = $('.p-artists-single').find('.artists-category .item a');
  let $tab_target = $('.p-artists-single').find('.artists-archive .box');
  $tab.on('click', function () {
    var index = $(this).parent().index();
    var nth = index + 1;
    $tab.parent().removeClass('is-active');
    $('.p-artists-single').find('.artists-category .item:nth-child(' + nth + ')').addClass('is-active');

    $tab_target.removeClass('is-active');
    $('.p-artists-single').find('.artists-archive .box:nth-child(' + nth + ')').addClass('is-active');
    return false;
  });

});

