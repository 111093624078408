import { gsap } from 'gsap';
import gsapCore from 'gsap/gsap-core';
import Scrolltrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(Scrolltrigger);
gsap.config({ nullTargetWarn: false, });

let menuFlg = true;

$(document).on('click', '.menu-btn', function () {
  let $this = $(this);
  let $tar = $this.parent().next();
  $this.find('.menu-trigger').stop().toggleClass('active');
  if (menuFlg) {
    gsap.to($tar, {
      autoAlpha: 1,
      duration: 0.3
    });
    $tar.addClass('is_active');
    //$this.find('.word').text('CLOSE');
    //$this.find('.word').addClass('active');
    $('header .wrap').addClass('active');
    $('body').addClass('stopscroll');
    $('html').addClass('stopscroll');
    menuFlg = false;


  } else {

    gsap.to($tar, {
      autoAlpha: 0,
      duration: 0.3
    });
    $tar.removeClass('is_active');
    //$(this).find('.word').text('MENU');
    //$(this).find('.word').removeClass('active');
    $('header .wrap').removeClass('active');
    $('body').removeClass('stopscroll');
    $('html').removeClass('stopscroll');
    menuFlg = true;
  }
  return false;
});

$(document).on('click', '.searchcloseBtn', function () {
  let $searchWin = $(this).parent().parent();
  gsap.to($searchWin, {
    autoAlpha: 0,
    duration: 0.3
  });
});

$(document).on('click', '.searchopenBtn', function () {
  let $searchWin = $('.searchWindow');
  gsap.to($searchWin, {
    autoAlpha: 1,
    duration: 0.3
  });
});

$('header .nav').find('a').on({
  "mouseenter": function () {
    $(this).addClass('current');
    $('header .nav').find('a:not(.current)').addClass('not-current');
  },
  "mouseleave": function () {
    $(this).removeClass('current');
    $('header .nav').find('a').removeClass('not-current');
  }
});

if (window.document.body.id === 'p-about') {
  $('.nav .child-menu').find('a').on('click', function () {
    let $tar = $(this).parent().parent().parent().parent();
    gsap.to($tar, {
      autoAlpha: 0,
      duration: 0.3
    });
    $('.menu-trigger').removeClass('active');
    $tar.removeClass('is_active');
    //$(this).find('.word').text('MENU');
    //$(this).find('.word').removeClass('active');
    $('header .wrap').removeClass('active');
    $('body').removeClass('stopscroll');
    $('html').removeClass('stopscroll');
    menuFlg = true;
  });
}
