
let element = document.getElementById("mv-target");
let parentElement = $('#mv-target').parent().parent();

if (element !== null) {
  if (element.complete) {
    let width = element.naturalWidth;
    let height = element.naturalHeight;
    if (height > width) {
      parentElement.addClass("portrait");
    }
  }
}

$(window).on('load resize', function () {
  if (window.matchMedia("(max-width:768px)").matches) {
  } else {
    $('.img1col-block .photo-wrap').each(function (index) {
      let $img1_W = $(this).data('width');
      let $img1_H = $(this).data('height');
      console.log($img1_W);
      if ($img1_W >= 1040) {
        let $limitH = $img1_W * 100 / $img1_H;
        $(this).addClass('limiter');
        //$(this).parent().css({ 'padding-top': $limitH + '%' });
        //$(this).find('figure img').css({ 'height': $limitH + '%' });
      };
    });
    $('.img2col-block').each(function (index) {
      let $img2_lW = $(this).find('.photo-wrap:nth-of-type(1)').data('width');
      let $img2_rW = $(this).find('.photo-wrap:nth-of-type(2)').data('width');
      let $total_2W = $img2_lW + $img2_rW;
      $(this).find('.photo-wrap:nth-of-type(1)').css({ 'width': $img2_lW * 100 / $total_2W + '%' });
      $(this).find('.photo-wrap:nth-of-type(2)').css({ 'width': $img2_rW * 100 / $total_2W + '%' });
    });
    $('.img3col-block').each(function (index) {
      let $img3_lW = $(this).find('.photo-wrap:nth-of-type(1)').data('width');
      let $img3_cW = $(this).find('.photo-wrap:nth-of-type(2)').data('width');
      let $img3_rW = $(this).find('.photo-wrap:nth-of-type(3)').data('width');
      let $total_3W = $img3_lW + $img3_cW + $img3_rW;
      console.log($img3_lW);
      $(this).find('.photo-wrap:nth-of-type(1)').css({ 'width': $img3_lW * 100 / $total_3W + '%' });
      $(this).find('.photo-wrap:nth-of-type(2)').css({ 'width': $img3_cW * 100 / $total_3W + '%' });
      $(this).find('.photo-wrap:nth-of-type(3)').css({ 'width': $img3_rW * 100 / $total_3W + '%' });
    });
  }
});

if (window.document.body.id === 'p-artists-single' || window.document.body.id === 'p-exhibitions-single') {
  $(window).on('load resize', function () {
    let $w = $('.mv-sec').find('img').width();
    let $targetW = $('.mv-sec').find('figcaption');
    $targetW.css({ 'width': $w });
  });

};
